import React from "react";
import { Link } from "react-router-dom";

const BusinessCardWithId = ({ info }) => {
  const { id, src, alt, title, information, img_Link, ceo_Name } = info;
  return (
    <div
      className={`flex flex-col lg:flex-row ${
        id % 2 === 0
          ? "lg:flex-row-reverse animate-popinRight"
          : "animate-popin"
      } items-center w-full lg:w-[70%] my-10`}
    >
      {/* Section 1 */}
      <div className="w-full lg:w-1/2 text-start flex flex-col gap-y-6 lg:gap-y-14 p-4">
        <Link to="https://www.google.com/">
          <h1 className="font-extrabold text-2xl text-blue-500 lg:text-black lg:text-3xl">
            {title}
          </h1>
        </Link>
        <p className="font-light text-sm lg:text-base">{information}</p>
        <div className="flex gap-3 items-center">
          <img className="w-12 rounded-full h-12" src={img_Link} alt={alt} />
          <div className="flex flex-col gap-y-1">
            <h4 className="font-bold text-lg">{ceo_Name}</h4>
            <span className="font-extralight text-sm">CEO</span>
          </div>
        </div>
      </div>
      {/* Section 2 */}
      <div
        className={`w-full lg:w-1/2 relative hover:cursor-pointer ${
          id % 2 !== 0 ? "lg:flex justify-end" : "lg:flex"
        }`}
      >
        <img
          src={src}
          className="z-10 hover:z-0 rounded-lg w-full lg:w-[90%] h-[275px] lg:h-[475px] object-cover"
          alt={alt}
        />
      </div>
    </div>
  );
};

export default BusinessCardWithId;
